import { Avatar, Box, Button, Menu, MenuItem, Paper, Typography } from '@mui/material';
import LogoLightImg from '../../../assets/images/logo_light.png';
import DummyUserImg from '../../../assets/images/dummy-user.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useToast } from '../../../hooks/use-toast';
import { useDispatch } from 'react-redux';
import { CommonActionTypes, CommonActions } from '../../../store/actions/common.actions';
import { useNavigate } from 'react-router-dom';

const AppHeader = () => {
  const { displayToast } = useToast();
  const { user } = useSelector((state: RootState) => state.authState.userInfo);
  const { isSidebarOpen } = useSelector((state: RootState) => state.commonState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = async () => {
    try {
      const response = await AuthService.signOut();
      if (!response.data.error) {
        sessionStorage.removeItem('studentInfo');
        sessionStorage.removeItem('onboarding');
        window.location.href = '/auth/signin';
      }
    } catch (err: any) {
      displayToast({
        isOpen: true,
        message: err?.response?.data?.message,
        type: 'error',
      });
    }
  };
  return (
    <Box>
      <Paper elevation={2} sx={{ boxShadow: '0 0 5px #dadada', borderRadius: 0 }}>
        <Box p={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box lineHeight={1} width={'230px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <img src={LogoLightImg} alt="" style={{ maxWidth: '200px' }} />
            <MenuIcon sx={{ width: '30px', height: '30px', cursor: 'pointer' }} onClick={() => {
              dispatch<CommonActions>({
                type: CommonActionTypes.IS_SIDEBAR_OPEN,
                payload: !isSidebarOpen,
              });
            }} />
          </Box>
          <Button
            onClick={handleClick}
            sx={{ textTransform: 'initial', textAlign: 'left', padding: 0, '&:hover': { background: 'transparent' } }}
          >
            <Box display={'flex'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
              <Avatar alt={`${user?.firstName} ${user?.lastName}`} src={DummyUserImg} />
              <Box pl={1}>
                <Typography lineHeight={1} fontWeight={500}>{`${user?.firstName} ${user?.lastName}`}</Typography>
                <Typography lineHeight={1} mt={0.5} textTransform={'capitalize'}>
                  {user?.role}
                </Typography>
              </Box>
            </Box>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{ '& .MuiMenu-paper': { minWidth: '150px' } }}
          >
            <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
            <MenuItem onClick={logout}>Sign Out</MenuItem>
            {/* <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          </Menu>
        </Box>
      </Paper>
    </Box>
  );
};

export default AppHeader;
